import { create } from "zustand";

type LatLng = { lat: number, lng: number }
type LocationStore = {
  place: google.maps.places.PlaceResult | null
  location: LatLng
  setPlace: (place: google.maps.places.PlaceResult) => void
  setLocation: (latLnt: LatLng) => void
}

export const useLocationStore = create<LocationStore>((set)=> {
  return {
    location: {
      lat: -23.533773,
      lng: -46.625290
    },
    place: null,
    setLocation: (latLnt: LatLng) => set({ location: latLnt }),
    setPlace: (place: google.maps.places.PlaceResult) => {
      if(place.geometry?.location){
        set({ location: place.geometry.location.toJSON() })
      }
      set({ place: place })
    }
  }
})